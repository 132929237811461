import React from 'react'

import { MdErrorOutline } from '@react-icons/all-files/md/MdErrorOutline'
import { Form as AntdForm, ConfigProvider, FormProps as AntdFormProps } from 'antd'
import { Transition } from 'react-transition-group'

import { colors } from 'settings/theme'

import { Message } from './style'

interface FormProps {
    children: React.ReactNode
    onSubmit?: (values) => void
    initialValues?: unknown
    layout?: 'horizontal' | 'inline' | 'vertical'
    color?: Color
    validation?: unknown
    error?: string
    onValuesChange?: AntdFormProps['onValuesChange']
    onFinishFailed?: AntdFormProps['onFinishFailed']
    size?: number
    form?: AntdFormProps['form']
}

const Form = ({
    form,
    children,
    onSubmit,
    error,
    initialValues,
    onFinishFailed,
    layout = 'vertical',
    color = colors.primary,
    onValuesChange,
    size = 50,
    ...props
}: FormProps): JSX.Element => {
    return (
        <ConfigProvider
            theme={
                {
                    token: {
                        colorPrimary: color,
                        controlHeight: size,
                        borderRadius: size / 2,
                        borderRadiusSM: size / 2,
                        colorPrimaryBg: color + 0x10
                    }
                }}
        >
            <Transition in={!!error} timeout={0}>
                {state => error && (
                    <Message state={state}>
                        <MdErrorOutline size={20} />
                        <p>{error}</p>
                    </Message>
                )}
            </Transition>
            <AntdForm
                form={form}
                onValuesChange={onValuesChange}
                wrapperCol={{ span: 24 }}
                initialValues={initialValues}
                onFinish={onSubmit}
                onFinishFailed={onFinishFailed}
                layout={layout}
                {...props}
            >
                {children}
            </AntdForm>
        </ConfigProvider>
    )
}

Form.Item = AntdForm.Item
Form.useForm = AntdForm.useForm

export default Form
