import styled from '@emotion/styled'

import { TransitionStatus } from 'react-transition-group'

import { setSize, setTransition } from 'utils/style'

import { colors, input } from 'settings/theme'

const height = 20

export const Element = styled.div<{error?: string, active: boolean}>`
  cursor: pointer;
  position: relative;
  font-size: ${setSize(input.fs)};
  width: ${setSize(height * 2)};
  height: ${setSize(height)};
  padding: ${setSize(height / 2)};
  transition: all 200ms;
  border-radius: ${setSize(input.br)};
  background-color: ${({ active }) => active ? colors.primary : 'grey'};
`

export const Handle = styled.div<{state: TransitionStatus}>`
  height: ${setSize(height - 6)};
  width:   ${setSize(height - 6)};
  border-radius: 100%;
  background-color: white;
  position: absolute;
  top: 3px;
  left: ${({ state }) => setTransition(state, {
        active: height + 2,
        disabled: 3
    })}px;
  box-shadow: 1px 0 8px #0000006e;
  transition: all 140ms ease;
`

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: auto auto auto auto;
  width: fit-content;
  align-items: center;
  grid-gap: 10px;
`

export const Label = styled.div`
  margin-right: 5px;
`
