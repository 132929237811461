import Checkbox from './checkbox'
import Image from './image'
import Password from './password'
import Select from './select'
import SelectAsync from './select-async'
import Switch from './switch'
import Text from './text'

export default {
    Checkbox,
    Image,
    Password,
    Select,
    SelectAsync,
    Text,
    Switch
}
