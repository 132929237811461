import React, { useEffect, useState } from 'react'

import { Form, Switch as AntdSwitch } from 'antd'

import { colors } from 'settings/theme'

import Text from 'components/ui/text'

import { Wrapper, Label } from './styles'

type Option = {
  value: string | boolean
  label: string
}

type Options = {
  active: Option
  inactive: Option
}

type SwitchProps = {
  options: Options
  label?: string
  name: string
  className?: string
  defaultValue?: boolean
  onChange?: (value: boolean | string) => void
  style?: React.CSSProperties
}

const Switch = ({ options, defaultValue, label, name, className, onChange, style }: SwitchProps): JSX.Element => {
    const [form] = Form.useForm()
    const [active, setActive] = useState<boolean>(defaultValue || false)

    const handleChange = (checked: boolean): void => {
        const value = checked ? options.active.value : options.inactive.value
        setActive(checked)
        form.setFieldsValue({ [name]: value })
        onChange && onChange(value)
    }

    return (
        <Wrapper className={className}>
            {label && <Label>{label} :</Label>}
            <Text color={!active ? colors.primary : 'grey'}>{options?.inactive.label}</Text>
            <Form.Item
                name={name}
                initialValue={defaultValue}
                valuePropName="checked"
                noStyle
            >
                <AntdSwitch onChange={handleChange} style={style} />
            </Form.Item>
            <Text color={active ? colors.primary : 'grey'}>{options?.active.label}</Text>
        </Wrapper>
    )
}

export default Switch
