import React from 'react'

import { Form, Select as AntdSelect } from 'antd'

import { SelectProps } from '../input'

import { Container } from './styles'

const Select = ({
    label,
    rules,
    required,
    col,
    options,
    multiple,
    loading,
    noStyle,
    style,
    onChange,
    disabled,
    ...props
}: SelectProps): JSX.Element => {
    return (
        <Container>
            <Form.Item
                noStyle={noStyle}
                label={label}
                rules={rules}
                required={required}
                wrapperCol={col}
                {...props}
            >
                <AntdSelect
                    onChange={onChange}
                    style={style}
                    mode={multiple ? 'multiple' : undefined}
                    disabled={disabled}
                    loading={loading}
                >
                    {options.map(option => <AntdSelect.Option value={option.value} key={option.value}>{option.label}</AntdSelect.Option>)}
                </AntdSelect>
            </Form.Item>
        </Container>
    )
}

export default Select
